
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$quickapp-primary: mat-palette($mat-indigo);
$quickapp-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$quickapp-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$quickapp-theme: mat-light-theme($quickapp-primary, $quickapp-accent, $quickapp-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($quickapp-theme);

/* You can add global styles to this file, and also import other style files */

@import 'app/assets/styles/custom-vendor';
@import '~bootstrap/scss/bootstrap';

@import 'app/assets/styles/vertical-tabs';
@import 'app/assets/styles/ngx-datatable';
@import 'app/assets/styles/styles-vendor.css';
@import 'app/assets/styles/bs-datepicker.css';

body, html {
  height: 100%;
  background: rgb(180,178,178);
  background: linear-gradient(180deg, rgba(180,178,178,1) 2%, rgba(246,246,250,1) 35%, rgba(246,246,250,1) 84%, rgba(180,178,178,1) 98%);
}

.reduced-font {
  font-size: 0.875rem;
}

.has-feedback-icon {
  position: relative;

  .form-control:not(.bootstrap-select) {
    padding-right: $input-height;
  }
}

input.form-control.left-status, textarea.form-control.left-status, .bootstrap-select.left-status > .dropdown-toggle {
  border-left-width: 5px;
}

.is-invalid .form-control-feedback,
.is-invalid ~ .form-control-feedback {
  color: theme-color("danger");
}

.is-valid .form-control-feedback,
.is-valid ~ .form-control-feedback {
  color: theme-color("success");
}

.ng-valid[required], .ng-valid.required {
  border-left: 5px solid theme-color("success");
}

.ng-invalid:not(form) {
  border-left: 5px solid theme-color("danger");
}

.bootstrap-select > .dropdown-toggle.ng-valid.required,
.bootstrap-select > .dropdown-toggle.ng-valid[required] {
  border-left: 5px solid theme-color("success");
}

.bootstrap-select > .dropdown-toggle.ng-invalid {
  border-left: 5px solid theme-color("danger");
}

.is-valid .bootstrap-select .dropdown-toggle,
.success .bootstrap-select .dropdown-toggle {
  border-color: theme-color("success");
}

.is-invalid .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
  border-color: theme-color("danger");
}

.theme-picker .theme-item {
  font-weight: 700;
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 5px;
}

.dropdown-toggle.btn-light {
  background-color: #fff;
  border-color: #ccc;
}

.bootstrap-select .dropdown-menu li a span.text {
  width: 100%;
}

.app-component .navbar .popover {
  min-width: 500px;
}


.modal.login-control {
  .modal-content {
    border: none;
    background-color: transparent;

    .modal-body {
      padding: 0;
    }
  }
}

/*.modal-backdrop.show {
  z-index: 9996;*/ /* Adjust this value as necessary */
/*}

.modal {
  z-index: 9997;*/ /* Ensure this is higher than the backdrop */
/*}*/


.form-control-feedback {
  position: absolute;
  top: 0;
  right: 1rem;
  z-index: 2;
  display: block;
  width: $input-height;
  height: $input-height;
  line-height: $input-height;
  text-align: center;
  pointer-events: none;
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: #eee;
}

.nav > .nav-item.active > a,
.nav > .nav-item.active > a:hover,
.nav > .nav-item.active > a:focus {
  background-color: #efefef;
}

.navbar .nav-item > a:hover,
.nav li.toolbaritem a:hover {
  color: #E03930;
}

.disabledContainer {
  pointer-events: none;
  opacity: 0.4;
}



.pageHeader {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
}

i.page-caption {
  vertical-align: baseline;
  font-size: 1.2em;
}

.alertify {
  top: 30%;
  white-space: pre-line;
}

.alertify-message {
  font-size: 1.1rem;
}

#toasta.toasta-position-top-right {
  top: 20px;
  right: 20px;
}

#toasta .toast.toasta-theme-bootstrap {
  font-size: 0.9em;
}

#toasta .toast.toasta-theme-bootstrap .toast-text .toast-title {
  font-size: 1.02em;
}

#toasta .toast-text .toast-msg {
  max-width: 1000px;
  max-height: 800px;
  display: inline-block;
  overflow: auto;
}


///*-webkit-filter: blur(5px) grayscale(90%);*/
///*body.modal-open .container {
//    -webkit-filter: blur(1px);
//    -moz-filter: blur(1px);
//    -o-filter: blur(1px);
//    -ms-filter: blur(1px);
//    filter: blur(1px);
//    filter: url("https://gist.githubusercontent.com/amitabhaghosh197/b7865b409e835b5a43b5/raw/1a255b551091924971e7dee8935fd38a7fdf7311/blur".svg#blur);
//    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='1');
//}*/
.vertical-center-flex {
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.tab-content {
}

///*Hack stop get ngx-datatable width to be full when inside bootstrap tab control*/
//.tab-pane .datatable-scroll {
//    width: 100% !important;
//}
.ngx-datatable.scroll-vertical {
  height: 64vh;
}

.black-anchor {
  color: #333;
}

.form-group .icon-addon .form-control {
  border-radius: 25px;
}

.icon-addon {
  position: relative;
  color: #555;
  display: block;
}

.icon-addon:after,
.icon-addon:before {
  display: table;
  content: " ";
}

.icon-addon:after {
  clear: both;
}

.icon-addon.addon-md .fa,
.icon-addon .fa {
  position: absolute;
  z-index: 2;
  font-size: 1rem;
  width: 20px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.icon-addon .fa.left-icon {
  left: 10px;
  margin-left: -2.5px;
}

.icon-addon .fa.right-icon {
  right: 10px;
  margin-right: -2.5px;
}

.icon-addon.addon-lg .form-control {
  line-height: 1.33;
  height: 46px;
  font-size: 18px;
}

.icon-addon.addon-lg .form-control.left-icon {
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 40px !important;
}

.icon-addon.addon-lg .form-control.right-icon {
  padding-top: 10px;
  padding-right: 40px !important;
  padding-bottom: 10px;
  padding-left: 16px;
}

.icon-addon.addon-sm .form-control {
  height: 30px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.icon-addon.addon-sm .form-control.left-icon {
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 28px !important;
}

.icon-addon.addon-sm .form-control.right-icon {
  padding-top: 5px;
  padding-right: 28px !important;
  padding-bottom: 5px;
  padding-left: 10px;
}

.icon-addon.addon-lg .fa {
  font-size: 18px;
  top: 4px;
}

.icon-addon.addon-lg .fa.left-icon {
  margin-left: 0;
  left: 11px;
}

.icon-addon.addon-lg .fa.right-icon {
  margin-right: 0;
  right: 11px;
}

.icon-addon.addon-md .form-control,
.icon-addon .form-control {
  font-weight: normal;
}

.icon-addon.addon-md .form-control.left-icon,
.icon-addon .form-control.left-icon {
  float: left;
  padding-left: 30px;
}

.icon-addon.addon-md .form-control.right-icon,
.icon-addon .form-control.right-icon {
  float: right;
  padding-right: 30px;
}

.icon-addon.addon-sm .fa {
  font-size: 12px;
  top: -1px;
}

.icon-addon.addon-sm .fa.left-icon,
.icon-addon.addon-sm .glyphicon.left-icon {
  margin-left: 0;
  left: 5px;
}

.icon-addon.addon-sm .fa.right-icon,
.icon-addon.addon-sm .glyphicon.right-icon {
  margin-right: 0;
  right: 5px;
}

.icon-addon .form-control:focus + .glyphicon,
.icon-addon:hover .glyphicon,
.icon-addon .form-control:focus + .fa,
.icon-addon:hover .fa {
  color: #2580db;
}

.clear-input {
  text-decoration: none !important;
  color: #b3b3b3 !important;
}

.clear-input:hover {
  color: #2580db !important;
}


.datatable-body-cell-left-global {
  text-align: left;
}


.datatable-body-cell-center-global {
  text-align: left;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.datatable-body .datatable-body-row{

}


.btn-link.grid {
  font-size: 0.700rem;
 
  padding: 0;
  //color: #D9230F;
}

.red {
  color: #91170a;
}


.greyOut {
  color: grey;
}

.datatable-body .datatable-body-row .datatable-body-cell a {
  cursor: pointer !important;
}

.close-large{
  font-size: x-large;
}

body .bs-datepicker-container .bs-datepicker-head {
  background-color: #fff;
}

body .bs-datepicker-container .bs-datepicker-head * {
  color: #000;
}

.float-middle {
  text-align: center;
}

.md-drppicker .ranges ul li button {
  //box-shadow: 0px 1px 4px rgba(0,0,0,0.6);
  //background-color: lightgray !important;
  text-decoration: underline;
  color: #3f51b5;
}

.md-drppicker .ranges ul li button:hover,
.md-drppicker .ranges ul li button:focus,
.md-drppicker .ranges ul li button:active {
  /*background-color: #3f51b5 !important;
  color: white;*/
}

.cal-link {
  //text-decoration: underline;
  // color: #3f51b5;
  cursor: pointer;
  /*box-shadow: 0px 1px 4px rgba(0,0,0,0.6);
  line-height: 18px;*/
}


.cal-link:hover,
.cal-link:active {
  background-color: #f7f7f7;
 // text-decoration: underline;
}

.form-check-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.109375rem;
  opacity: 0;
}

@font-face {
  font-family: 'fontello';
  src: url('/fonts/fontello.eot');
  src: url('/fonts/fontello.eot') format('embedded-opentype'),
      url('/fonts/fontello.woff') format('woff'),
      url('/fonts/fontello.ttf') format('truetype'),
      url('/fonts/fontello.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.pswFont {
  -webkit-text-security: disc;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  color: red;
  font-size: 16px;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  /* add spacing to better separate each image */
  letter-spacing: 2px;
}

.custom-small-select {
  height: calc(1.5em + .5rem + 2px) !important;
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
  padding-left: .5rem !important;
  font-size: .71094rem !important;
}

.search-header {
  padding: .5rem 1rem;
  margin-bottom: -3px;
  background-color: #ddd;
  -webkit-box-shadow: -1px 4px 31px -7px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 4px 31px -7px rgba(0,0,0,0.75);
  box-shadow: -1px 4px 31px -7px rgba(0,0,0,0.75);
  border-radius: 5px;

  .no-padding {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .btn {
    height: 25px !important;
  }

  .text-subscript {
    height: 18px;
    margin-top: -7px;
    padding: 0 3px 0 0;
  }
}

.standard {
  height: 26px !important;
  font-size: 0.700rem !important;
  line-height: 30px;
}

.user-select-all {
  -webkit-user-select: all; /* Chrome all / Safari all */
  -moz-user-select: all; /* Firefox all */
  -ms-user-select: all; /* IE 10+ */
  user-select: all;
}

.page-container {
  .datatable-header {
    padding: .5rem 0;
    color: theme-color("light");
    /*background: #ff3019;*/ /* Old browsers */
    /*background: -moz-linear-gradient(top, #ff3019 0%, #cf0404 100%);*/ /* FF3.6-15 */
    /*background: -webkit-linear-gradient(top, #ff3019 0%,#cf0404 100%);*/ /* Chrome10-25,Safari5.1-6 */
    /*background: linear-gradient(to bottom, #ff3019 0%,#cf0404 100%);*/ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  background-color: whitesmoke;
  -webkit-box-shadow: 0px 1px 5px 1px rgba(89,87,89,1);
  -moz-box-shadow: 0px 1px 5px 1px rgba(89,87,89,1);
  box-shadow: 0px 1px 5px 1px rgba(89,87,89,1);
  padding-bottom: 5px;
  height: 95%;
}

.fixed-footer-buttons {
 
    position: fixed;
    right: 0;
    bottom: 15px;
    left: 10%;
    z-index: 1030;


  }

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: .8125rem;
}

.mat-progress-bar {
  height: 12px !important;
  width: 0;
  border-radius: 3px !important;
}

/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: whitesmoke;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

/* The sidebar links */
.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  /* font-size: 25px;
  color: #818181;*/
  display: block;
  transition: 0.3s;
}


/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s; /* If you want a transition effect */
  padding: 20px;
}



/* For the checkbox itself (unchecked state) */
.blue-checkbox .custom-control-input {
  border-color: blue;
  background-color: transparent; /* Ensure the background is visible */
}

/* For the checkbox itself (checked state) */
.blue-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #02014a; /* Blue border */
  background-color: #02014a; /* Blue background */
}


/* Optional: Add hover effect */
.blue-checkbox .custom-control-input:hover {
  border-color: lightblue;
}

/* Optional: Focus outline when the checkbox is focused */
.blue-checkbox .custom-control-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.25);
}





